import React, { useState, useEffect, useRef, memo} from "react";


import "../styles/title.scss";
import HALO from "vanta/dist/vanta.fog.min";
import * as THREE from "three";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

export const EFFECT = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        HALO({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 600.0,
          minWidth: 600.0,
          scale: 1.0,
          scaleMobile: 1.0,
          highlightColor: 0x3fc6ff,
          midtoneColor: 0xff83f0,
          lowlightColor: 0x8065f5,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  return (
    <div ref={vantaRef}>
    </div>
  );
};

const Title = memo(() => {
  return (
    <section className="title_container">
      <div className="title_background">
        {/* <EFFECT></EFFECT> */}
      </div>
      <div className="title_filter"></div>{" "}
      <div className="title_contents">
        <h1>Timothy Fong</h1>

        <div className="line"></div>
        <h2>Portfolio</h2>
        <h4>
          Junior at Brown University studying Applied Mathematics-Computer Science
        </h4>
        <button class="shrek"></button>
      </div>
    </section>
  );
});

export default Title;
