import React, { memo } from "react";
import "../styles/about.scss";
import img from "../images/profile.png";
import osu from "../images/osuicon.png";
import zerg from "../images/zerg.png";


import {
  IoPerson,
  IoLocationSharp,
  IoMailSharp,
  IoCalendarClearOutline,
  IoLogoLinkedin,

} from "react-icons/io5";

const About = memo(({ refs }) => {
  return (
    <section ref={refs} className="about-section">
      <div className="section-title">ABOUT ME</div>

      <div className="about-content">
        <img
          className="about-picture"
          src={img}
          alt="about-"
        ></img>
        <div className="about-info">
          <b className="info-title">"Timothy Fong"</b>
          <div className="info">
            <br />
            Currently I am a student attending Brown University in Providence, Rhode Island, and I have a strong interest in math, computer science, and music. 
            I'm currently majoring in Applied Mathematics-Computer Science; I'm also actively participating in Brown's music community by being a part of the Applied Music Chamber program and playing violin for Brown's chamber orchestra club. I'm an avid member of the Brown-RISD game development club as well, where I gain experience working with programmers and artists to create small indie games using game engines such as Unity.
            Outside of school I like to yo-yo, as well as take my dog (Tofu) on walks during the school breaks.
          </div>
          <ul>
            <li>
              <div className="info-li">
                <IoLocationSharp className="icon" /> Providence, Rhode Island
              </div>
            </li>
            <li>
              <div className="info-li">
                <IoMailSharp className="icon" /> timothy_fong@brown.edu
              </div>
            </li>
            <li>
              <div className="info-li">
                <IoLogoLinkedin className="icon" /> 
                <a href="https://www.linkedin.com/in/timfon/"> LinkedIn</a>
              </div>
            </li>
            {/* <li>
              <div className="info-li">
                <img className="osu-icon" src={osu}></img>
                <a href="https://osu.ppy.sh/users/25345406"> Osu profile</a>
              </div>
            </li>
            <li>
              <div className="info-li">
                <img className="zerg-icon" src={zerg}></img>
                <a href="https://sc2pulse.nephest.com/sc2/?type=character&id=9276236&m=1#player-stats-mmr"> Zerg</a>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </section>
  );
});

export default About;
