import React, { memo } from "react";

import "../styles/portfolio.scss";
import paint from "../images/Screenshot 2024-01-13 at 4.59.12 PM.png";
import screen from "../images/Screenshot 2024-01-13 at 4.53.25 PM.png";
import buzz from "../images/gallery.jpg";
import raylib from "../images/trim.gif";
import pilot from "../images/pilot.png";
import terminal from "../images/Screenshot 2024-01-13 at 5.32.39 PM.png";
import pederson from "../images/peders (1).png"
import report from './main.pdf';
import splash from "../images/splash.png";


const Portfolio = memo(({ refs }) => {
  return (
    <section ref={refs} className="portfolio-section">
      <div className="section-title">PORTFOLIO</div>
      <div className="portfolio-content">
      <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src={pederson} alt="pederson" />
            </div>
              <br />
            <div className="title">Zero-Knowledge Proof for Graph 3-Coloring</div>
              <br />
            <div className="contents">
              This project was the term project for CS1515, Applied Cryptography, at Brown University. I worked with <a href="https://edwardwibowo.com/">Edward Wibowo</a> on this project, implementing a zero-knowledge proof protocol in Go for graph 3-coloring using a server and client for the verifier and prover. Mapped each of the three colors to another distinct color to ensure the verifier cannot learn the true colors of the graph. Each round, the colors were randomly shuffled, preventing the verifier from learning from previous rounds.
Some benchmarks were conducted to analyze the efficiency of the protocol: 1,000,000 repetitions takes 41.509 seconds on average to complete.
            </div>
            <a
              className="button"
              href="https://github.com/claby2/3color-zkp"
            >
              github link
            </a>
            <a
              className="button"
              href="https://youtu.be/PNrXs2Wu73E"
            >
              video demo
            </a>
            <a
              className="button"
              href= {report}
            >
              final report
            </a>

          </div>
        </div>
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src={pilot} alt="pilot" />
            </div>
              <br />
            <div className="title">Paper Pilot</div>
              <br />
            <div className="contents">
              Paper Pilot is a webapp where users can upload a DOI link of their choice, and the application will recommend papers similar to that DOI link. 
              It utilizes React Routing to navigate between pages, Python for backend, typescript, Google's firebase to store the user's bookmarked papers, a DocToVec model that converts each paper into a vector, and a kd-tree algorithm for the recommendation algorithm. 

            </div>
            <a
              className="button"
              href="https://github.com/cs0320-f23/term-project-tfong1-ryhuang-dhan25-eko10"
            >
              github link
            </a>
          </div>
        </div>
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img
                src={raylib}
                alt="raylib"
              />
            </div>
            <br />
            <div className="title">Raylib Python C Foreign Function Interface</div>
            <div className="contents">
              <br />
              Contributed to the popular open source graphics library Raylib by translating code examples from C to Python.
Implemented 6 examples from the “core” and “models” modules: split screen, vr simulator, pixel perfect camera, window flags, waving cubes, and 3D free camera.
            </div>
            <a
              className="button"
              href="https://github.com/electronstudio/raylib-python-cffi"
            >
              github link
            </a>

          </div>
        </div>
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src={buzz} alt="LLM Buzzfeed" />
            </div>
            <br />
            <div className="title">Aistrology</div>
            <div className="contents">
              <br />
              Collaborated with 3 programmers to produce “Aistrology”, a browser-based personality quiz powered by OpenAI API.
              Utilized Typescript, Javascript, HTML, React, and Tailwind CSS.

            </div>
            <a
              className="button"
              href="https://github.com/Timfon/LLM-Buzzfeed"

            >
              github link
            </a>
            <a
              className="button"
              href="https://devpost.com/software/aistrology"
            >
              devpost

            </a>
          </div>
        </div>
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img
                src={screen}
                alt="maps"
              />
            </div>
            <br />
            <div className="title">Maps</div>
            <div className="contents">
              <br />
              Maps is an interactive webapp that allows users to look at historical redlining data on a map through visualizations. Users can click anywhere on the map and get broadband access data by county and state. Users can also use the search bar to search for terms in the area description data of the file.
            </div>
            <a className="button" href="https://github.com/cs0320-f23/maps-dhan25-tfong1">

              github link
            </a>
          </div>
        </div>
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src={paint} alt="죄송합니다 이미지를 불러오지 못했습니다." />
            </div>
            <br />
            <div className="title">Code Search</div>
            <br />
            <div className="contents">
            Searches through 28,000,000+ public github repositories and displays the code search results to the user frontend.
Allows users to easily search for code in public github repositories by inputting sample code or general search terms. 
            </div>{" "}
            <a
              className="button"
              href="https://github.com/Timfon/Code-Search"

            >
              github link
            </a>
          </div>
        </div>
        <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src={terminal} alt="terminal" />
            </div>
            <br />
            <div className="title">Shell</div>
            <div className="contents">
              <br />
              Created a shell in C from scratch. The shell can parse user input, handle signals, reap foreground and background processes, as well as redirect standard output/input using redirection symbols: "{">"}", "{">>"}", "{"<"}".
            </div>
            <a
              className="button"
              href="https://github.com/cs0330-fall2023/5-shell-2-Timfon"
            >
              github link
            </a>

          </div>
        </div>
    <div className="portfolio-info-area">
          <div className="portfolio-info">
            <div className="picture-area">
              <img src={splash} alt="splash" />
            </div>
            <br />
            <div className="title">MacOS Rice</div>
            <div className="contents">
              <br />
              Customized my MacOS desktop using the kitty for terminal, yabai for window management, and skhd for keybindings. Additionally, I used the zsh shell with the powerlevel10k theme, as well as using the neovide graphical user interface.
              For the status bar, I used sketchy bar, and for the borders, I used JankyBorders by Felix Kratz. Feel free to checkout my dotfiles below!
              

            </div>
            <a
              className="button"
              href="https://github.com/Timfon/dotfiles-timfon.git">
              github link
            </a>

          </div>
        </div>

      </div>
    </section>
  );
});

export default Portfolio;
